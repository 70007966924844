import * as React from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { Layout, Shared } from '../../components';
import { trackLandingComplete } from '../../utils/Tracking';

const MessageReceivedPage = () => {
  React.useEffect(() => {
    trackLandingComplete();
  }, []);

  return (
    <>
      <Layout.NavMenu solidColor notFixed />
      <section className="bg-primary text-white mt-n4 py-5">
        <Container fluid="lg" className="content">
          <Row>
            <Col className="text-start">
              <h1>Thanks for Contacting Icon Property Tax Appeal!</h1>
              <p>We’ll be in touch shortly. In the meantime, watch below about how property tax appeal works.</p>
              <a href="/" className="text-white">
                Back to Homepage
              </a>
            </Col>
          </Row>
        </Container>
      </section>
      <Shared.TaxAppealsMadeEasy />
      <Layout.Footer />
    </>
  );
};

export default MessageReceivedPage;

export const Head = () => <Layout.PageHead subtitle="Thank You!" googleNoIndex />;
